import styled from "styled-components"
import { colors } from "@yarno/thread"
import SiteContainer from "../common/Container"
import { navBreak, lg, nav } from "../../utils/breakpoints"

export const FreeTrialButton = styled.li`
  line-height: 5rem !important;
  border-top: none !important;
  display: block;
  margin-right: 1rem !important;

  a {
    display: block;
    border-radius: 4px;
    background: #f9b523;
    text-align: center;
    font-family: "Raleway";
    font-weight: bold;
    font-size: 16px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #ffffff;
    margin: 1rem 0 2rem 0;
    transition: all 0.1s ease-in;
  }

  @media ${navBreak} {
    display: ${props => (props.mobile ? `none` : `inline-block`)} !important;
    line-height: 1 !important;
    position: relative;
    top: -2px;
    a {
      padding: 0 2rem !important;
      line-height: 4rem !important;
      height: 4rem;
      margin: 0;
      font-size: 1.4rem;

      &:hover {
        color: white !important;
        background: #e6a720;
      }
    }
  }
`

export const SignInButton = styled.li`
  line-height: 5rem !important;
  border-top: none !important;
  display: ${props => (props.mobile ? `block` : `none`)} !important;

  a {
    display: block;
    border-radius: 4px;
    background: #1fa38e;
    text-align: center;
    font-family: "Raleway";
    font-weight: bold;
    font-size: 16px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #ffffff;
    margin: 1rem 0 2rem 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transition: all 0.1s ease-in;
  }

  @media ${navBreak} {
    display: ${props => (props.mobile ? `none` : `inline-block`)} !important;
    line-height: 1 !important;
    position: relative;
    top: -2px;

    a {
      padding: 0 2rem !important;
      line-height: 4rem !important;
      height: 4rem;
      margin: 0;
      font-size: 1.4rem;

      &:hover {
        color: white !important;
        background: #198070;
      }
    }
  }
`

export const SubnavList = styled.ul`
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);

  ${props =>
    props.open &&
    `
    background: #24BCA5;
    max-height: 1000px;
    transition: max-height 1s ease-in-out;
    li { border-top: 1px solid #25C1A9 !important; }
  `}
`

export const Nav = styled.nav`
  ${props =>
    props.isOpen &&
    `
  height: 100vh;
  position: fixed;
  top: 0; left:0; bottom; 0; right: 0;
  overflow-y: scroll;
  `}

  background: white;
  width: 100%;
  font-family: ${props => props.theme.fonts.heading};

  ${props =>
    props.hasScrolled && `box-shadow: rgb(0 0 0 / 5%) 4px 4px 12px 0px;`}

  #logo {
    margin: 0;
    display: inline-block;
    line-height: 6rem;
    img {
      width: 9.3rem;
      height: 2.9rem;
    }
  }

  ul {
    margin: 0;

    > li {
      line-height: 3rem;
      &:last-of-type {
        padding-right: 0;
      }
    }
  }

  li {
    display: inline-block;
    position: relative;
    font-size: 1.6rem;
    border-top: 1px solid #23b8a1;
  }

  a {
    text-decoration: none;
  }

  ${props =>
    props.isHome &&
    `
    background: ${props.theme.colors.primary};

    a {
      color: white;
    }

  `}

  @media ${navBreak} {
    height: 6rem;
    padding: 0 1rem;

    li > a:hover {
    }

    ul > li {
      line-height: 1.2;
      line-height: 6rem;
      &:hover {
        ul {
          display: block;
          background: white;
          padding: 0;
          margin: 0;

          li {
            padding: 0;
            line-height: 4rem;
            display: block;
            &:before {
              content: "";
            }
          }

          a {
            display: block;
            line-height: 1.2;
            padding: 1rem 2rem;
            color: #8e9091;
            &:hover {
              color: ${props => props.theme.colors.secondary};
            }
          }
        }
      }
    }

    li {
      border-top: none;
      a {
        padding: 0 2rem;

        &:hover {
          color: ${props => props.theme.colors.primaryHover};
        }
      }
    }

    li ul {
      position: absolute;
      top: 5.5rem;
      min-width: 22rem;
      box-shadow: 4px 4px 12px 0 rgba(0, 0, 0, 0.05);
      display: none;
      border-radius: 4px;

      :after {
        content: "";
        position: absolute;
        top: -0.8rem;
        left: 15%;
        width: 0;
        height: 0;
        border-bottom: solid 8px white;
        border-left: solid 8px transparent;
        border-right: solid 8px transparent;
      }
    }
  }
`
export const Container = styled(SiteContainer)`
  position: relative;

  @media ${navBreak} {
    display: flex;
    justify-content: space-between;
  }
`

export const NavLinks = styled.ul`
  margin: 0;
  display: ${props => (props.mobileNavOpen ? "block" : "none")};
  padding: 0;

  & > li > a:hover {
    ${props =>
      props.isHome
        ? `
    color: white;
    opacity: 0.8;
    `
        : `

          color: ${props => props.theme.colors.primaryHover};

        `}
  }

  li {
    display: block;
  }

  li > a {
    line-height: 2rem;
    display: block;
    padding: 1.5rem 0;
    position: relative;
    cursor: pointer;
  }

  li li a {
    padding-left: 2rem;
  }
  li li li a {
    padding-left: 4rem;
  }

  span {
    display: inline-block;
    position: absolute;
    right: 0rem;
    height: 5rem;
    top: 0;
    z-index: 3;
    padding: 2rem;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media ${navBreak} {
    display: block;
    background: ${props =>
      props.isHome ? props.theme.colors.primary : "white"};

    li {
      display: inline-block;
      position: relative;
      font-size: 1.6rem;
      margin-right: 0rem;

      &:last-of-type {
        margin-right: 0;
      }

      &:hover {
        ${SubnavList} {
          height: inherit;
          max-height: inherit;
          overflow: visible;

          li {
            margin-right: 0;
          }
        }
      }
    }

    li > a {
      line-height: 6rem;
      padding: 0 2.5rem 0 0;
    }

    li.with-subnav > a {
      padding: 0 5.5rem 0 0;
    }

    span {
      height: 6rem;
      padding: 0 2rem 0 1rem;
      right: 1.5rem;
      svg {
        width: 12px;
      }
    }
  }

  @media ${lg} {
    li > a {
      padding: 0 3.5rem 0 0;
    }

    li.with-subnav > a {
      padding: 0 5.5rem 0 0;
    }
  }
`
