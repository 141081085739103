import React, { Fragment, useState } from "react"
import { Link } from "gatsby"
import Hamburger from "../Hamburger"
import {
  Nav,
  SubnavList,
  SignInButton,
  Container,
  NavLinks,
  FreeTrialButton
} from "./styles"
import { useScrolled } from "../../hooks/useScrolled"
import linkResolver from "../../utils/linkResolver"

const Subnav = ({ open, list }) => {
  if (!list || list.length <= 1) return null

  return (
    <SubnavList open={open}>
      {list.map((link, index) => {
        return (
          <NavLink
            key={`${link.level_2_link.document.uid}-${index}`}
            link={link}
            level={2}
          />
        )
      })}
    </SubnavList>
  )
}

const Caret = ({ handleSubnavToggle }) => {
  return (
    <span onClick={handleSubnavToggle}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" width="16">
        <path d="M360.5 217.5l-152 143.1C203.9 365.8 197.9 368 192 368s-11.88-2.188-16.5-6.562L23.5 217.5C13.87 208.3 13.47 193.1 22.56 183.5C31.69 173.8 46.94 173.5 56.5 182.6L192 310.9l135.5-128.4c9.562-9.094 24.75-8.75 33.94 .9375C370.5 193.1 370.1 208.3 360.5 217.5z" />
      </svg>
    </span>
  )
}

const NavLink = ({ link, level }) => {
  const [subnavOpen, setSubnavOpen] = useState(false)
  const primary = level == 1 ? link.primary : link
  const primaryLink = primary[`level_${level}_link`]
  const label = primary[`level_${level}_link_label`]

  const hasSubnav = link && link.items && link.items.length > 0
  const handleSubnavToggle = e => {
    e.preventDefault()
    setSubnavOpen(!subnavOpen)
  }

  if (!primaryLink || !primaryLink.document)
    return (
      <li className={`${hasSubnav ? "with-subnav" : ""}`}>
        <a>
          {label}
          {hasSubnav && <Caret handleSubnavToggle={handleSubnavToggle} />}
        </a>
        <Subnav open={subnavOpen} list={link.items} />
      </li>
    )

  const linkClassname = `${hasSubnav ? "with-subnav" : ""} ${
    label === "Free Trial" ? "button" : ""
  }`.trim()

  return label === "Free Trial" ? (
    <FreeTrialButton>
      <Link to={linkResolver(primaryLink.document)}>{label}</Link>
    </FreeTrialButton>
  ) : (
    <li className={linkClassname}>
      <Link to={linkResolver(primaryLink.document)}>
        {label}
        {hasSubnav && <Caret handleSubnavToggle={handleSubnavToggle} />}
      </Link>

      <Subnav open={subnavOpen} list={link.items} />
    </li>
  )
}

const NavBar = ({
  mobileNavOpen,
  setMobileNavOpen,
  isHome,
  companyName,
  logo,
  links
}) => {
  const { hasScrolled } = useScrolled(60)
  const inverted = mobileNavOpen || isHome

  return (
    <Nav isHome={inverted} hasScrolled={hasScrolled} isOpen={mobileNavOpen}>
      <Container>
        <Link to="/" id="logo">
          <img src={inverted ? logo + "&exp=100" : logo} alt={companyName} />
        </Link>

        <Hamburger
          isOpen={mobileNavOpen}
          inverted={inverted}
          handleClick={() => setMobileNavOpen(!mobileNavOpen)}
        />

        <NavLinks mobileNavOpen={mobileNavOpen} isHome={inverted}>
          <SignInButton mobile={true}>
            <a href="https://learn.yarno.com.au">Sign in</a>
          </SignInButton>

          {links.map((link, index) => {
            return (
              <NavLink key={`link-${link.id}-${index}`} link={link} level={1} />
            )
          })}

          <SignInButton mobile={false}>
            <a href="https://learn.yarno.com.au">Sign in</a>
          </SignInButton>
        </NavLinks>
      </Container>
    </Nav>
  )
}
export default NavBar
